import {
  IPlatformAPI,
  IWixAPI,
} from '@wix/native-components-infra/dist/src/types/types';

import initSchemaLogger, { Logger } from '@wix/bi-logger-wix-search-platform';

import { getBIDefaults } from './defaultParams';
import {
  createBICorrelationId,
  BICorrelationIdKey,
  BICorrelationIdStore,
} from './correlationId';
import {
  SearchDocumentType,
  ISuggestionDocument,
} from '@wix/client-search-sdk';
import { IReportError } from '../monitoring/sentry';

function getSuggestionsStats(suggestions?: ISuggestionDocument[]) {
  let resultCount = 0;
  const stats = {};

  if (suggestions) {
    suggestions.forEach(suggestion => {
      resultCount += suggestion.options.length;
      stats[suggestion.documentType] = suggestion.options.length;
    });
  }

  return {
    resultCount,
    stats,
    resultsArray: JSON.stringify(stats),
  };
}

export function createSearchPlatformBiLogger(
  platformAPIs: IPlatformAPI,
  wixCodeApi: IWixAPI,
  reportError: IReportError,
) {
  // NOTE: editor version of biLoggerFactory does not add some required defaults,
  // so set it manually
  const biLogger: Logger = initSchemaLogger(platformAPIs.biLoggerFactory?.())({
    defaults: getBIDefaults(platformAPIs, wixCodeApi.window.viewMode),
  });
  const correlationIdStore = new BICorrelationIdStore(
    platformAPIs,
    reportError,
  );

  return {
    searchSubmit: (params: { isDemoContent: boolean; searchQuery: string }) => {
      const correlationId = createBICorrelationId();

      correlationIdStore.set(BICorrelationIdKey.SearchSubmit, correlationId);

      void biLogger.searchBoxSearchSubmit({
        isDemo: params.isDemoContent,
        target: params.searchQuery,
        correlationId,
      });
    },

    searchBoxSuggestionsRequestStarted: (startParams: {
      searchQuery: string;
    }) => {
      const correlationId = createBICorrelationId();
      const startTime = Date.now();

      correlationIdStore.set(
        BICorrelationIdKey.SearchSuggestions,
        correlationId,
      );

      const commonProps = {
        target: startParams.searchQuery,
        correlationId,
      };

      void biLogger.searchBoxSuggestionsRequestStarted(commonProps);

      return function searchBoxSuggestionsRequestFinished(finishParams: {
        success: boolean;
        error?: string;
        suggestions?: ISuggestionDocument[];
      }) {
        const loadingDuration = Date.now() - startTime;

        const { resultCount, resultsArray } = getSuggestionsStats(
          finishParams.suggestions,
        );

        if (finishParams.success) {
          void biLogger.searchBoxSuggestionsRequestFinished({
            ...commonProps,
            success: true,
            resultCount,
            resultsArray,
            loadingDuration,
          });
        } else {
          void biLogger.searchBoxSuggestionsRequestFinished({
            ...commonProps,
            success: false,
            error: finishParams.error,
            loadingDuration,
          });
        }
      };
    },

    searchBoxSuggestionClick: (params: {
      url: string;
      searchQuery: string;
      index: number;
      documentType: string;
      suggestions?: ISuggestionDocument[];
    }) => {
      const { resultsArray } = getSuggestionsStats(params.suggestions);

      void biLogger.searchBoxSuggestionClick({
        correlationId: correlationIdStore.get(
          BICorrelationIdKey.SearchSuggestions,
        ),
        pageUrl: params.url,
        documentType: params.documentType,
        target: params.searchQuery,
        searchIndex: params.index,
        resultsArray,
      });
    },

    searchBoxSuggestionSearchAllClick: (params: { searchQuery: string }) => {
      void biLogger.searchBoxSuggestionShowAllClick({
        correlationId: correlationIdStore.get(
          BICorrelationIdKey.SearchSuggestions,
        ),
        // NOTE: what to do if there is only one tab? (so no All tab)
        documentType: SearchDocumentType.All,
        target: params.searchQuery,
      });
    },

    searchBoxSuggestionShowAllClick: (params: {
      searchQuery: string;
      documentType: string;
    }) => {
      void biLogger.searchBoxSuggestionShowAllClick({
        correlationId: correlationIdStore.get(
          BICorrelationIdKey.SearchSuggestions,
        ),
        documentType: params.documentType,
        target: params.searchQuery,
      });
    },
  };
}
