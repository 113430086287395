import {
  IWidgetController,
  I$WWrapper,
} from '@wix/native-components-infra/dist/src/types/types';

import { ISuggestionDocument } from '@wix/client-search-sdk';
import { debounce } from 'lodash';

import { IWidgetControllerConfig } from './platform.types';
import { createSearchPlatformBiLogger } from './bi';

import { ISearchResultsWixCode } from './searchResultsControllerFactory';

interface I$W extends Pick<I$WWrapper, 'props'> {
  (key: string): any;
}

type I$WResult<T> = T[] & T;
interface I$WEvent<T, S = {}> {
  target: T;
  syntheticEvent: S;
}

interface ISearchSuggestionsGroupWixCode extends ISuggestionDocument {
  url: string;
  sectionId: string;
}

interface ISearchSuggestionsWixCode {
  query: string;
  searchAllUrl: string;
  groups: ISearchSuggestionsGroupWixCode[];
  // TODO: remove together with `ISearchSuggestionsDeprecated`
  documents: ISearchSuggestionsGroupWixCode[];
}

interface ISearchBoxWixCode {
  onSubmit(handler: (e: I$WEvent<ISearchBoxWixCode>) => void): void;
  onChange(handler: (e: I$WEvent<ISearchBoxWixCode>) => void): void;

  onSuggestionSelect(
    handler: (
      e: I$WEvent<
        ISearchBoxWixCode,
        {
          // https://github.com/wix-private/wix-ui-santa/blob/master/src/components/SearchBox/SearchBox.js#L102
          // --.from ..suggestion
          url: string;
          // --.from ..params
          globalIndex: number;
          groupId: string;
          query: string;
        }
      >,
    ) => void,
  ): void;

  onSuggestionsFooterClick(
    handler: (
      e: I$WEvent<
        ISearchBoxWixCode,
        {
          url: string;
          query: string;
        }
      >,
    ) => void,
  ): void;
  onSuggestionGroupSelect(
    handler: (
      e: I$WEvent<
        ISearchBoxWixCode,
        {
          url: string;
          query: string;
          groupId: string;
        }
      >,
    ) => void,
  ): void;

  suggestionsEnabled: boolean;
  suggestions: ISearchSuggestionsWixCode | null;
  value: string;
}

export async function searchAppControllerFactory(
  controllerConfig: IWidgetControllerConfig,
): Promise<IWidgetController> {
  const {
    wixCodeApi,
    platformAPIs,
    searchSDK,
    searchLocation,
    reportError,
  } = controllerConfig;
  // TODO: cleanup types when resolved issue with type
  // https://github.com/wix-private/site-search/issues/92
  const $w: I$W = controllerConfig.$w;
  const isMobile = wixCodeApi.window.formFactor === 'Mobile';

  const isSiteViewMode = wixCodeApi.window.viewMode === 'Site';
  const isDemoContent = !isSiteViewMode;

  const bi = createSearchPlatformBiLogger(
    platformAPIs,
    wixCodeApi,
    reportError,
  );

  return {
    async pageReady() {
      const absoluteUrl = await searchLocation.getSearchResultsAbsoluteUrl();

      function withBaseUrl(url: string) {
        return `${absoluteUrl}${url}`;
      }

      const searchBoxes: I$WResult<ISearchBoxWixCode> = $w('@searchBox');
      const searchResults: I$WResult<ISearchResultsWixCode> = $w(
        '@searchResults',
      );

      const hasSearchBoxOnPage = searchBoxes.length > 0;
      const isOnSearchResultsPage = searchResults.length > 0;

      if (!hasSearchBoxOnPage) {
        return;
      }

      searchBoxes.onSubmit(e => {
        const searchBox = e.target;
        const searchQuery = searchBox.value;

        bi.searchSubmit({
          isDemoContent,
          searchQuery,
        });

        searchBox.value = '';

        if (!isOnSearchResultsPage) {
          // NOTE: navigation with not empty search query works correctly only in `Site` view mode
          // TODO: we could navigate with searchQuery in preview mode when `sv_editorNativeComponents` opened
          void searchLocation.navigateToSearchResults({
            query: isSiteViewMode ? searchQuery : '',
          });
        } else {
          // NOTE: editor version of searchResults component, doesn't return valid controller instance
          // so `changeQuery` call does not affect search results component
          // should be fixed when `sv_editorNativeComponents` opened
          // https://github.com/wix-private/site-search/issues/130
          searchResults.changeQuery(searchQuery);
        }
      });

      /**
       * Clear all suggestions if mobile.
       * If switch to desktop editor script will set demo suggestion again
       */
      if (isMobile) {
        searchBoxes.suggestions = null;
      }

      if (!isDemoContent && !isMobile) {
        const loadSuggestions = async (e: I$WEvent<ISearchBoxWixCode>) => {
          const searchBox = e.target;
          const searchQuery = searchBox.value;

          if (!searchBox.suggestionsEnabled) {
            return;
          }

          if (searchQuery.length < 3) {
            searchBox.suggestions = null;
            return;
          }

          const biSearchBoxSuggestionsRequestFinished = bi.searchBoxSuggestionsRequestStarted(
            { searchQuery },
          );

          try {
            const { suggestions } = await searchSDK.getSuggestions({
              query: searchQuery,
              limit: 10,
            });

            const searchAllRelativeUrl = searchLocation.encodePath({
              query: searchQuery,
            });

            const suggestionGroups = suggestions.map(suggestion => {
              const sectionRelativeUrl = searchLocation.encodePath({
                documentType: suggestion.documentType,
                query: searchQuery,
              });

              return {
                ...suggestion,
                sectionId: suggestion.documentType,
                url: withBaseUrl(`/${sectionRelativeUrl}`),
              };
            });

            searchBox.suggestions = {
              query: searchQuery,
              searchAllUrl: withBaseUrl(`/${searchAllRelativeUrl}`),
              groups: suggestionGroups,
              documents: suggestionGroups,
            };

            biSearchBoxSuggestionsRequestFinished({
              success: true,
              suggestions,
            });
          } catch (error) {
            biSearchBoxSuggestionsRequestFinished({
              success: false,
              error: error.toString(),
            });

            reportError(error);

            throw error;
          }
        };

        searchBoxes.onChange(debounce(loadSuggestions, 300));
        searchBoxes.onSubmit(e => {
          const searchBox = e.target;
          searchBox.suggestions = null;
        });

        searchBoxes.onSuggestionSelect(e => {
          const searchBox = e.target;
          const searchQuery = e.syntheticEvent.query;
          const url = e.syntheticEvent.url;
          const relativeUrl = url.replace(wixCodeApi.location.baseUrl, '');

          bi.searchBoxSuggestionClick({
            url,
            searchQuery,
            index: e.syntheticEvent.globalIndex,
            documentType: e.syntheticEvent.groupId,
            suggestions: searchBox.suggestions
              ? searchBox.suggestions.groups || searchBox.suggestions.documents
              : undefined,
          });
          wixCodeApi.location.to?.(relativeUrl);
        });

        searchBoxes.onSuggestionsFooterClick(e => {
          const searchQuery = e.syntheticEvent.query;
          const url = e.syntheticEvent.url;
          const relativeUrl = url.replace(wixCodeApi.location.baseUrl, '');

          bi.searchBoxSuggestionSearchAllClick({
            searchQuery,
          });

          wixCodeApi.location.to?.(relativeUrl);
        });

        searchBoxes.onSuggestionGroupSelect(e => {
          const searchQuery = e.syntheticEvent.query;
          const url = e.syntheticEvent.url;
          const relativeUrl = url.replace(wixCodeApi.location.baseUrl, '');

          bi.searchBoxSuggestionShowAllClick({
            searchQuery,
            documentType: e.syntheticEvent.groupId,
          });

          wixCodeApi.location.to?.(relativeUrl);
        });
      }
    },
  };
}
