import { debounce, isEqual, isMatch, merge } from 'lodash';

import { IWixAPI } from '@wix/native-components-infra/dist/src/types/types';
import {
  ClientSearchSDK,
  ISearchDocument,
  ISearchRequest,
  ISearchSDKDemoContentOptions,
  SearchDocumentType,
  ISearchSDKMockOptions,
} from '@wix/client-search-sdk';

import initSchemaLogger, { Logger } from '@wix/bi-logger-wix-search-widget';
import {
  Settings,
  CategoryList,
  searchAppSettings,
  CategorySettings,
  uncompress,
} from '@wix/search-results-settings-definitions';

import { IWidgetControllerConfig } from './platform.types';

import { ISearchLocation } from './location';

import { Spec, SPECS_SCOPE } from '../lib/specs';
import Experiments from '@wix/wix-experiments';

import { SearchRequestStatus } from '../types/types';
import {
  createBICorrelationId,
  BICorrelationIdKey,
  BICorrelationIdStore,
  getBIDefaults,
  getBITotals,
  getAbsoluteDocumentIndex,
} from './bi';
import { APP_DEFINITION_ID, WIDGET_ID } from '../lib/constants';

import { IFedopsLogger } from './types';
import {
  ISearchSortConfig,
  SearchResultsControllerStoreState,
} from './SearchResultsControllerStore.types';
import { IReportError } from './monitoring/sentry';
import {
  getSortOptions,
  getSortRequest,
  getDefaultSortOption,
  isOrderingSupported,
} from './sort/get-sort-options';
import { SortOptionId } from '@wix/search-results';
import { search } from './search';
import { createSearchRequestBI } from './bi/createSearchRequestBI';

function equalSearchRequests(
  searchRequest1: ISearchRequest,
  searchRequest2: ISearchRequest,
) {
  return isEqual(searchRequest1, searchRequest2);
}

type CategoryEntry = [SearchDocumentType, CategorySettings];

const getVisibleCategories = (
  settings: Settings,
  availableDocumentTypes: SearchDocumentType[],
) => {
  const categoryList: CategoryList = merge(
    {},
    uncompress(searchAppSettings.categoryList.getDefaultValue()),
    settings.categoryList,
  );

  const visibleCategories = Object.entries(categoryList).filter(
    (c: CategoryEntry) =>
      c[1].visible &&
      availableDocumentTypes.includes(c[0] as SearchDocumentType),
  );

  return visibleCategories;
};

const withDocumentType = (
  searchRequest: ISearchRequest,
  visibleCategories: Array<[String, CategorySettings]>,
): ISearchRequest => {
  if (
    searchRequest.documentType &&
    visibleCategories
      .map(([documentType]) => documentType)
      .includes(searchRequest.documentType)
  ) {
    return searchRequest;
  }

  const documentType = visibleCategories.length
    ? (visibleCategories[0][0] as SearchDocumentType)
    : SearchDocumentType.All;

  return {
    ...searchRequest,
    documentType,
  };
};

export class SearchResultsControllerStore {
  private readonly wixCodeApi: IWixAPI;
  private readonly setComponentProps: (
    props: SearchResultsControllerStoreState,
  ) => void;

  private readonly fedopsLogger: IFedopsLogger;
  private readonly biLogger: Logger;
  private readonly biCorrelationIdStore: BICorrelationIdStore;
  private readonly reportError: IReportError;
  private readonly searchSDK: ClientSearchSDK;
  private readonly searchLocation: ISearchLocation;
  private readonly experiments: Experiments;

  // private settingsEventHandler: ISettingsEventHandler;
  private demoContentOptions: ISearchSDKDemoContentOptions;
  private state: SearchResultsControllerStoreState;
  private documentTypes: SearchDocumentType[];

  constructor(
    {
      appParams,
      platformAPIs,
      wixCodeApi,
      setProps,
      reportError,
      searchSDK,
      searchLocation,
      siteLanguage,
    }: IWidgetControllerConfig,
    settings: Settings,
  ) {
    this.wixCodeApi = wixCodeApi;
    this.setComponentProps = setProps;
    this.reportError = reportError;
    this.searchSDK = searchSDK;
    this.searchLocation = searchLocation;
    this.documentTypes = [];

    const cssBaseUrl = appParams.baseUrls.staticsBaseUrl;

    this.experiments = new Experiments({
      baseUrl: 'https://www.wix.com',
    });
    void this.experiments.load(SPECS_SCOPE);

    this.state = {
      language: siteLanguage,
      cssBaseUrl,
      searchResultsAbsoluteUrl: '',
      settings,
      experiments: this.experiments.all(),
      searchRequest: this.getSearchRequestFromLocationPath(
        wixCodeApi.location.path,
        settings.itemsPerPage,
      ),
      searchRequestStatus: SearchRequestStatus.Initial,
      documentTypes: [],
      onDocumentTypeChange: this.handleDocumentTypeChange,
      onQuerySubmit: this.handleQuerySubmit,
      onPageChange: this.handlePageChange,
      onDocumentClick: this.handleDocumentClick,
      onAppLoaded: this.handleAppLoaded,
      // TODO: cleanup when resolved https://github.com/wix-private/native-components-infra/pull/28
      viewMode: wixCodeApi.window.viewMode,
      isDemoContent: wixCodeApi.window.viewMode !== 'Site',
      isMobile: wixCodeApi.window.formFactor === 'Mobile',
      sortConfig: {
        onSortChange: this.onSortChange,
        selectedSortOption: 0,
        showSort: false,
        sortOptions: [],
      },
      reportError,
      searchResponseTotals: {},
      searchResponse: {
        documents: [],
        totalResults: 0,
      },
    };

    // this.initSettingsEventHandler(appPublicData);

    if (this.state.isDemoContent) {
      this.setDemoContentOptions({
        shouldMockDocumentTypes: false,
        shouldHaveSearchResults: true,
      });
    }

    // NOTE: http://wixplorer.wixpress.com/out-of-iframe/guides/Reference/Fedops
    this.fedopsLogger = platformAPIs.fedOpsLoggerFactory?.getLoggerForWidget({
      appId: APP_DEFINITION_ID,
      widgetId: WIDGET_ID,
    });

    // NOTE: editor version of biLoggerFactory does not add some required defaults,
    // so set it manually
    this.biLogger = initSchemaLogger(platformAPIs.biLoggerFactory?.())({
      defaults: getBIDefaults(platformAPIs, wixCodeApi.window.viewMode),
    });
    this.biCorrelationIdStore = new BICorrelationIdStore(
      platformAPIs,
      this.reportError,
    );

    wixCodeApi.location.onChange(({ path }) => {
      const stateSearchRequest = this.state.searchRequest;
      const locationSearchRequest = this.getSearchRequestFromLocationPath(
        path,
        stateSearchRequest.paging.pageSize,
      );

      if (equalSearchRequests(locationSearchRequest, stateSearchRequest)) {
        return;
      }

      void this.changeSearchRequest(locationSearchRequest);
    });
  }

  private getSearchRequestFromLocationPath(
    path: string[],
    pageSize: number,
  ): ISearchRequest {
    const locationSearchRequest = this.searchLocation.decodePath(
      /**
       * first item in location path -- is "page name"
       * we should decode all parts after
       */
      path.slice(1).join('/'),
    );

    return this.searchLocation.toSDKSearchRequest(
      locationSearchRequest,
      pageSize,
    );
  }

  private isSSR() {
    return this.wixCodeApi.window.rendering.env === 'backend';
  }

  private setDemoContentOptions(partialOptions: ISearchSDKMockOptions) {
    if (
      this.demoContentOptions &&
      isMatch(this.demoContentOptions, partialOptions)
    ) {
      return;
    }

    this.demoContentOptions = {
      ...this.demoContentOptions,
      ...partialOptions,
    };
    this.searchSDK.useDemoContent(this.demoContentOptions);
  }

  private setState(partialState: Partial<SearchResultsControllerStoreState>) {
    this.state = {
      ...this.state,
      ...partialState,
    };

    this.setComponentProps(this.state);
  }

  private async search(
    searchRequest: ISearchRequest,
    visibleCategories: Array<[string, CategorySettings]>,
  ) {
    searchRequest = this.withOrdering(
      withDocumentType(searchRequest, visibleCategories),
    );

    const {
      searchResponse,
      searchResponseTotals,
      searchSamples,
    } = await search({
      searchRequest,
      searchSDK: this.searchSDK,
      previousQuery: this.state.previousQuery,
      isMobile: this.state.isMobile,
      previousTotals: this.state.searchResponseTotals,
      searchResultsAbsoluteUrl: this.state.searchResultsAbsoluteUrl,
      searchLocation: this.searchLocation,
      visibleCategories,
      bi: createSearchRequestBI({
        searchRequest,
        isDemoContent: this.state.isDemoContent,
        biLogger: this.biLogger,
        correlationId: this.biCorrelationIdStore.get(
          BICorrelationIdKey.SearchSubmit,
        ),
      }),
    });

    this.setState({
      searchRequest,
      searchResponse,
      searchResponseTotals,
      searchSamples,
      searchRequestStatus: SearchRequestStatus.Loaded,
      previousQuery: searchRequest.query,
      sortConfig: this.getUpdatedSortConfig(
        searchRequest.documentType,
        searchResponse.totalResults,
      ),
    });
  }

  private async changeSearchRequest(
    searchRequestRaw: ISearchRequest,
  ): Promise<void> {
    this.setState({
      searchRequestStatus: SearchRequestStatus.Loading,
    });

    const visibleCategories = getVisibleCategories(
      this.state.settings,
      this.documentTypes,
    );

    try {
      await this.search(searchRequestRaw, visibleCategories);
    } catch (error) {
      this.setState({
        error: JSON.stringify(error),
        searchRequestStatus: SearchRequestStatus.Failed,
        previousQuery: undefined,
        searchResponseTotals: {},
        searchSamples: [],
      });
      this.reportError(error);
    }
  }

  private readonly changeSearchRequestLazy: (
    request: ISearchRequest,
  ) => void = debounce(this.changeSearchRequest, 500);

  updateSettings(settings: Settings) {
    const prevSettings = this.state.settings;

    this.setState({
      settings,
    });

    if (prevSettings.itemsPerPage !== settings.itemsPerPage) {
      this.changeSearchRequestLazy({
        ...this.state.searchRequest,
        paging: {
          page: 1,
          pageSize: settings.itemsPerPage,
        },
      });
    }

    // https://github.com/wix-private/site-search/issues/153
    // this.settingsEventHandler.updateData(appPublicData);
  }

  getSearchSDK(): ClientSearchSDK {
    return this.searchSDK;
  }

  private applySearchRequest(searchRequest: ISearchRequest) {
    if (
      this.state.isDemoContent ||
      equalSearchRequests(this.state.searchRequest, searchRequest)
    ) {
      void this.changeSearchRequest(searchRequest);
      return;
    }

    void this.searchLocation.navigateToSearchResults(
      this.searchLocation.toLocationSearchRequest(searchRequest),
    );
  }

  changeDocumentType = (documentType: SearchDocumentType) => {
    const searchRequest = this.state.searchRequest;

    this.setState({
      sortConfig: {
        ...this.state.sortConfig,
        selectedSortOption: getDefaultSortOption(documentType),
      },
    });

    this.applySearchRequest({
      ...searchRequest,
      documentType,
      paging: {
        ...searchRequest.paging,
        page: 1,
      },
    });
  };

  changeQuery = (query: string) => {
    const searchRequest = this.state.searchRequest;

    this.applySearchRequest({
      ...searchRequest,
      query,
      paging: {
        ...searchRequest.paging,
        page: 1,
      },
    });
  };

  changePage = (page: number) => {
    const searchRequest = this.state.searchRequest;

    this.applySearchRequest({
      ...searchRequest,
      paging: {
        ...searchRequest.paging,
        page,
      },
    });
  };

  updateDemoMode(data: {
    shouldHaveSearchResults: boolean;
    shouldSetNonAllDocumentType: boolean;
  }) {
    const { shouldHaveSearchResults, shouldSetNonAllDocumentType } = data;

    let searchRequest = this.state.searchRequest;
    let isSearchRequestChanged = false;
    let isDemoContentOptionsChanged = false;

    if (
      shouldHaveSearchResults !==
      this.demoContentOptions.shouldHaveSearchResults
    ) {
      this.setDemoContentOptions({
        shouldHaveSearchResults,
      });

      isDemoContentOptionsChanged = true;
    }

    if (shouldSetNonAllDocumentType) {
      const documentTypeSource = this.state.documentTypes.find(
        ({ documentType }) => documentType !== SearchDocumentType.All,
      );

      if (
        documentTypeSource &&
        documentTypeSource.documentType !== searchRequest.documentType
      ) {
        searchRequest = {
          ...searchRequest,
          documentType: documentTypeSource.documentType,
        };

        isSearchRequestChanged = true;
      }
    }

    if (isDemoContentOptionsChanged || isSearchRequestChanged) {
      this.applySearchRequest(searchRequest);
    }
  }

  private readonly handleAppLoadStarted = () => {
    this.fedopsLogger.appLoadStarted();
  };

  private readonly handleAppLoaded = () => {
    this.fedopsLogger.appLoaded();
  };

  private readonly handleDocumentTypeChange = (
    documentType: SearchDocumentType,
    biParams?: { source: string },
  ): void => {
    const correlationId = this.biCorrelationIdStore.get(
      BICorrelationIdKey.SearchSubmit,
    );

    void this.biLogger.documentTypeChange({
      isDemo: this.state.isDemoContent,
      target: documentType,
      correlationId,
      ...biParams,
    });

    this.changeDocumentType(documentType);
  };

  private readonly handleQuerySubmit = (query: string): void => {
    const correlationId = createBICorrelationId();

    this.biCorrelationIdStore.set(
      BICorrelationIdKey.SearchSubmit,
      correlationId,
    );

    // TODO: add clickOrigin
    void this.biLogger.searchSubmit({
      isDemo: this.state.isDemoContent,
      target: query,
      correlationId,
    });

    this.changeQuery(query);
  };

  private readonly handlePageChange = (page: number) => {
    // const correlationId = this.biCorrelationIdStore.get(
    //   BICorrelationIdKey.SearchSubmit,
    // );

    // TODO: uncomment, when need `pageChange` event.
    // void this.biLogger.pageChange({
    //   isDemo: this.state.isDemoContent,
    //   target: page,
    //   correlationId,
    // });

    this.changePage(page);
  };

  private readonly handleDocumentClick = (
    document: ISearchDocument,
    index: number,
  ): void => {
    const { isDemoContent, searchRequest, searchResponseTotals } = this.state;
    const correlationId = this.biCorrelationIdStore.get(
      BICorrelationIdKey.SearchSubmit,
    );

    void this.biLogger.documentClick({
      isDemo: isDemoContent,
      target: document.title,
      pageUrl: document.url,
      documentType: searchRequest.documentType,
      correlationId,
      searchIndex: getAbsoluteDocumentIndex(searchRequest.paging, index),
      resultsArray: getBITotals(searchResponseTotals),
    });

    this.wixCodeApi.location.to?.(document.relativeUrl);
  };

  private readonly onSortChange = (selectedSortOption: SortOptionId) => {
    const { settings } = this.state;
    if (selectedSortOption !== this.state.sortConfig.selectedSortOption) {
      this.setState({
        sortConfig: {
          ...this.state.sortConfig,
          selectedSortOption,
        },
      });
      void this.changeSearchRequest({
        ...this.state.searchRequest,
        paging: {
          page: 1,
          pageSize: settings.itemsPerPage,
        },
      });
    }
  };

  shouldShowSort(
    documentType: SearchDocumentType | undefined,
    resultsCount: number,
  ): boolean {
    return (
      this.experiments.enabled(Spec.Sorting) &&
      isOrderingSupported(documentType) &&
      resultsCount > 0
    );
  }

  getInitialSortConfig(
    documentType: SearchDocumentType | undefined,
    resultsCount: number,
  ): ISearchSortConfig {
    return {
      ...this.state.sortConfig,
      selectedSortOption: getDefaultSortOption(documentType),
      showSort: this.shouldShowSort(documentType, resultsCount),
      sortOptions: getSortOptions(documentType),
    };
  }

  getUpdatedSortConfig(
    documentType: SearchDocumentType | undefined,
    resultsCount: number,
  ): ISearchSortConfig {
    return {
      ...this.state.sortConfig,
      showSort: this.shouldShowSort(documentType, resultsCount),
      sortOptions: getSortOptions(documentType),
    };
  }

  withOrdering(searchRequest: ISearchRequest) {
    return {
      ...searchRequest,
      ordering: getSortRequest(this.state.sortConfig.selectedSortOption),
    };
  }

  async setInitialState(): Promise<void> {
    this.handleAppLoadStarted();

    if (!this.biCorrelationIdStore.has(BICorrelationIdKey.SearchSubmit)) {
      // NOTE: there are no correlationId from previous submit, so create new one
      this.biCorrelationIdStore.set(
        BICorrelationIdKey.SearchSubmit,
        createBICorrelationId(),
      );
    }

    const startTime = Date.now();

    void this.biLogger.loadingStarted({});

    try {
      const [searchResultsAbsoluteUrl, documentTypes] = await Promise.all([
        await this.searchLocation.getSearchResultsAbsoluteUrl(),
        await this.searchSDK.getDocumentTypes(),
      ]);
      this.documentTypes = documentTypes.map(t => t.documentType);

      this.setState({
        searchResultsAbsoluteUrl,
        documentTypes,
      });

      if (this.state.isDemoContent) {
        this.setDemoContentOptions({
          documentTypesForSearchResults: this.documentTypes,
        });
      }

      const visibleCategories = getVisibleCategories(
        this.state.settings,
        this.documentTypes,
      );

      await this.search(this.state.searchRequest, visibleCategories);

      void this.biLogger.loadingFinished({
        loadingDuration: Date.now() - startTime,
        success: true,
      });
    } catch (error) {
      this.setState({
        error: JSON.stringify(error),
        searchRequestStatus: SearchRequestStatus.Failed,
        previousQuery: undefined,
        searchResponseTotals: {},
        searchSamples: [],
      });

      void this.biLogger.loadingFinished({
        loadingDuration: Date.now() - startTime,
        success: false,
        error: error.toString(),
      });

      this.reportError(error);
    }

    // NOTE: on CSR appLoaded is called from componentDidMount
    if (this.isSSR()) {
      this.handleAppLoaded();
    }
  }
}
