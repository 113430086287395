import { getSettingsValues } from '@wix/tpa-settings';
import {
  searchResultsSettings,
  searchAppSettings,
  CompressedCategoryList,
  CategoryList,
  uncompress,
} from '@wix/search-results-settings-definitions';
import { IControllerConfig } from '@wix/native-components-infra/dist/src/types/types';

export const extractSettings = (config: IControllerConfig) => {
  const componentData = config.publicData.COMPONENT || {};
  const componentSettings = getSettingsValues(
    componentData,
    searchResultsSettings,
  );

  const appData = config.publicData.APP || {};
  const appSettings: {
    categoryList: CompressedCategoryList;
  } = getSettingsValues(appData, searchAppSettings);

  const newAppSettings: {
    categoryList: CategoryList;
  } = { categoryList: uncompress(appSettings.categoryList) };

  return {
    settings: { ...newAppSettings, ...componentSettings },
    publicData: { ...appData, ...componentData },
  };
};
