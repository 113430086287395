import {
  IWidgetController,
  IControllerConfig,
  I$W,
} from '@wix/native-components-infra/dist/src/types/types';

import { createEventHandler } from '@wix/tpa-settings';

import { IWidgetControllerConfig } from './platform.types';

import { SearchResultsControllerStore } from './SearchResultsControllerStore';
import { extractSettings } from './extractSettings';

export interface ISearchResultsWixCode {
  changeQuery(query: string): void;
}

export interface ISettingsEvents {
  previewStateChange: {
    shouldHaveSearchResults: boolean;
    shouldSetNonAllDocumentType: boolean;
  };
}

export async function searchResultsControllerFactory(
  controllerConfig: IWidgetControllerConfig,
): Promise<IWidgetController> {
  const { settings, publicData } = extractSettings(controllerConfig.config);
  const eventHandler = createEventHandler<ISettingsEvents>(publicData);

  eventHandler.on('previewStateChange', value => {
    controllerStore.updateDemoMode(value);
  });

  eventHandler.onReset(() => {
    controllerStore.updateDemoMode({
      shouldHaveSearchResults: true,
      shouldSetNonAllDocumentType: true,
    });
  });

  let controllerStore: SearchResultsControllerStore;

  try {
    controllerStore = new SearchResultsControllerStore(
      controllerConfig,
      settings,
    );
  } catch (error) {
    controllerConfig.reportError(error);
    throw error;
  }

  return {
    pageReady() {
      return controllerStore.setInitialState();
    },

    updateConfig($w: I$W, config: IControllerConfig) {
      const { settings, publicData } = extractSettings(config);

      eventHandler.notify(publicData);
      void controllerStore.updateSettings(settings);
    },

    exports: {
      // NOTE: used for mocks in tests
      searchSDK: controllerStore.getSearchSDK(),
      changeQuery: controllerStore.changeQuery,
    } as ISearchResultsWixCode,
  };
}
