import { searchResultsControllerFactory } from './searchResultsControllerFactory';
import { searchAppControllerFactory } from './searchAppControllerFactory';
import {
  IWidgetController,
  IViewerScript,
  IAppData,
  IPlatformAPI,
  IWixAPI,
  IPlatformServices,
} from '@wix/native-components-infra/dist/src/types/types';
import { parseUrl } from '@wix/native-components-infra/dist/src/urlUtils';
import { ClientSearchSDK } from '@wix/client-search-sdk';

import { createSearchLocation, ISearchLocation } from './location';
import { IWidgetControllerConfig } from './platform.types';
import { buildSentryOptions, IReportError } from './monitoring/sentry';
import { getSiteLanguage } from './getSiteLanguage';

let reportError: IReportError;
let searchSDK: ClientSearchSDK;
let searchLocation: ISearchLocation;
let siteLanguage: string;

function createControllers(
  controllersConfig: IWidgetControllerConfig[],
): Array<Promise<IWidgetController>> {
  return controllersConfig.map(config => {
    const extendedConfig: IWidgetControllerConfig = {
      ...config,
      reportError,
      searchSDK,
      searchLocation,
      siteLanguage,
    };

    // TODO: think about wrapping all methods returned from the factory with try/catch
    // ecom: https://github.com/wix-private/ecom/blob/b0589677f084a6cf09a58a6d8af5d3da60981acd/client/wixstores-client/wixstores-client-core/src/viewer-script/createViewerScript.ts
    return config.type === 'SearchAppController'
      ? searchAppControllerFactory(extendedConfig)
      : searchResultsControllerFactory(extendedConfig);
  });
}

function getUrlOrigin(url: string, reportError: IReportError): string {
  const urlParsed = parseUrl(url);

  if (!urlParsed.protocol || !urlParsed.host) {
    reportError('missing protocol or host in parsed url');
    return '';
  }

  return `${urlParsed.protocol}://${urlParsed.host}`;
}

// NOTE: avoid using async with this function due to bug with 'dev mode' + 'async function initAppForPage' + 'ViewerScriptWrapper'
// https://github.com/wix-private/native-components-infra/blob/4611438dc088bedc51300b23d4eff9c44afd1cbc/src/viewerScriptHandler.ts#L181
// https://github.com/lodash/lodash/blob/4.14.1/lodash.js#L11179 vs https://github.com/lodash/lodash/blob/4.17.15/lodash.js#L11654
function initAppForPage(
  initParams: IAppData,
  apis: IPlatformAPI,
  wixCodeApi: IWixAPI,
  platformServices: IPlatformServices,
): Promise<void> {
  // NOTE: this Worker environment is used in viewer only
  // Environment for editor would be  - "Editor-mode wrapper"
  // see more: https://github.com/wix-private/native-components-infra/blob/f7a58d0ac5b2da8d23712aa9d97acca22a33657a/src/viewerScriptHandler.ts#L157
  const sentryOptions = buildSentryOptions({
    environment: 'Worker',
  });
  const sentryInstance = platformServices.monitoring.createMonitor(
    sentryOptions.dsn,
    config => ({
      ...config,
      ...sentryOptions.config,
      ...{ tags: { msid: platformServices.bi.metaSiteId } },
    }),
  );

  reportError = sentryInstance.captureException.bind(sentryInstance);

  const siteBaseUrl = wixCodeApi.location.baseUrl;
  // NOTE: use default api url for editor environment
  // https://wix.slack.com/archives/CAKBA7TDH/p1568384247066100
  // https://sentry.io/organizations/wix_o/issues/1169886738/
  const apiBaseUrl =
    wixCodeApi.window.viewMode === 'Site' && siteBaseUrl
      ? getUrlOrigin(siteBaseUrl, reportError)
      : undefined;
  siteLanguage = getSiteLanguage(wixCodeApi);

  searchLocation = createSearchLocation(wixCodeApi);

  searchSDK = new ClientSearchSDK({
    token: initParams.instance,
    baseURL: apiBaseUrl,
    siteBaseURL: siteBaseUrl,
    language: siteLanguage,
  });

  searchSDK.onError(error => {
    sentryInstance.captureException(error, {
      extra: {
        siteBaseUrl,
        apiBaseUrl,
        config: error.config,
        code: error.code,
        request: error.request,
        response: error.response,
      },
    });
  });

  wixCodeApi.site.onInstanceChanged(event => {
    searchSDK.setToken(event.instance);
  });

  return Promise.resolve();
}

export const viewerScript: IViewerScript = {
  initAppForPage,
  createControllers,
};
